import * as React from 'react'
import Head from 'next/head'

import {
  Container,
  Box,
  Text,
  Link,
  Grid,
  Spacer,
  Flex,
} from '@chakra-ui/react'
import {
  AppressaLogoBase,
  Footer,
  PRIMARY_COLOR,
  PRIMARY_LINK_COLOR,
  SECONDARY_TEXT_COLOR,
} from '../components'

export default function NotFoundPage() {
  return (
    <>
      <Head>
        <title>Page Not Found</title>
      </Head>
      <Flex direction="column" minH="100vh">
        <Box mt="6" ml="7">
          <AppressaLogoBase width="200" height="32" />
        </Box>
        <Container data-cy="not-found-page">
          <Grid
            minH="60vh"
            alignContent="center"
            justifyContent="center"
            style={{ color: '#000A4C' }}
          >
            <Box textAlign="center">
              <Text as="h1" fontSize="5xl">
                Oh No! It&apos;s a 404
              </Text>
              <Text
                as="p"
                fontSize="medium"
                mt="1"
                style={{ color: SECONDARY_TEXT_COLOR }}
              >
                This page cannot be found
              </Text>
              <Text as="p" fontWeight="extrabold" fontSize="large" mt="6">
                Still wanting to explore Credit Card offers? Visit<br></br>
                <Link
                  data-cy="compare-credit-fair-external-link"
                  href="https://www.comparecredit.com/credit-cards/credit-range/fair/"
                  isExternal
                  style={{
                    color: PRIMARY_LINK_COLOR,
                  }}
                >
                  CompareCredit.com
                </Link>
              </Text>
            </Box>
          </Grid>
        </Container>
        <Spacer />
        <Footer backgroundColor={PRIMARY_COLOR} minHeight={170} />
      </Flex>
    </>
  )
}
